import { AdditionalPhonemeInfo, EmotionEvent } from '@inworld/web-core';
import { Box } from '@mui/material';

import InworldChar3D from './InworldChar3D';

export function Avatar({
  emotionEvent,
  phonemes,
  visible,
  url,
}: {
  emotionEvent?: EmotionEvent;
  phonemes: AdditionalPhonemeInfo[];
  visible: boolean;
  url: string;
}) {
  return (
    <Box
      className="avatar"
      sx={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        display: visible ? 'flex' : 'none',
        zIndex: '1',
      }}
    >
      <Box
        sx={{
          borderRadius: '1.75rem',
          overflow: 'hidden',
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
      >
        {/* ぼかし効果を適用するための子要素 */}
        <Box
          sx={{
            position: 'absolute', // 親要素に対して絶対位置指定
            top: '-8px', // ぼかし効果による白い線を隠すために調整
            left: '-8px', // 同上
            width: 'calc(100% + 16px)', // ぼかし効果による白い線を隠すために調整
            height: 'calc(100% + 16px)', // 同上
            backgroundImage: 'url("./assets/bg.jpg")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            filter: 'blur(4px)',
            zIndex: -1,
          }}
        />
        <InworldChar3D
          url={url}
          phonemes={phonemes}
          emotionEvent={emotionEvent}
        />
      </Box>
    </Box>
  );
}
