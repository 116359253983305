import './index.css';

import { ThemeProvider } from '@mui/material';
import ReactDOM from 'react-dom/client';
import ReactGA4 from 'react-ga4';

import App from './App';
import theme from './app/theme';

ReactGA4.initialize('G-2VKY71XFMX');
ReactGA4.send({ hitType: "pageview", page: window.location.pathname });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
);
