import { Box } from '@mui/material';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { save as saveConfiguration } from '../helpers/configuration';
import { ConfigurationSession } from '../types';

const FIELD_CHARACTER_NAME = 'character.name';
const FIELD_SCENE_NAME = 'scene.name';
const BASE_PATH = 'workspaces/default-zvtiv8zm0t6l5iavjymnjw/characters/';

interface CharacterProps {
  characterSuffix: string;
  onStart: () => void;
}

export const Character = ({ characterSuffix, onStart }: CharacterProps) => {
  const { getValues, setValue } = useFormContext<ConfigurationSession>();

  const onClickSave = useCallback(() => {
    const characterPath = BASE_PATH + characterSuffix;
    setValue(FIELD_CHARACTER_NAME, characterPath);
    setValue(FIELD_SCENE_NAME, characterPath);
    saveConfiguration(getValues());
    onStart();
  }, [getValues, setValue, characterSuffix, onStart]);


  return (
    <Box sx={{ m: 2, display: 'flex', justifyContent: 'left' }}>
      <div onClick={onClickSave} style={{ cursor: 'pointer', textAlign: 'left' }}>
        <div style={{ color: 'white', fontSize: '18px'}}>
          {characterSuffix === 'unite_test' ? 'unite_test-coach' : characterSuffix}
        </div>
      </div>
    </Box>
  );
};