import { Button } from '@mui/material';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { save as saveConfiguration } from '../helpers/configuration';
import { ConfigurationSession } from '../types';

const FIELD_CHARACTER_NAME = 'character.name';
const FIELD_SCENE_NAME = 'scene.name';
const BASE_PATH = 'workspaces/default-zvtiv8zm0t6l5iavjymnjw/characters/';

interface CharacterButtonProps {
  characterSuffix: string;
  onHandleClickOpen: () => void;
}

export const CharacterButton = ({ characterSuffix, onHandleClickOpen }: CharacterButtonProps) => {
  const { getValues, setValue } = useFormContext<ConfigurationSession>();

  const onClickSave = useCallback(() => {
    const characterPath = BASE_PATH + characterSuffix;
    setValue(FIELD_CHARACTER_NAME, characterPath);
    setValue(FIELD_SCENE_NAME, characterPath);
    saveConfiguration(getValues());
    onHandleClickOpen();
  }, [getValues, setValue, characterSuffix, onHandleClickOpen]);

  const buttonText = characterSuffix === 'unite_quiz_prod_' ? 'TUBC Trivia Challenge' 
                    : characterSuffix === 'unite_coach_prod_' ? 'Ask Your Basketball "Mentor Unite"' 
                    : '';
  const buttonTextJp = characterSuffix === 'unite_quiz_prod_' ? 'TUBC クイズチャレンジ' 
  : characterSuffix === 'unite_coach_prod_' ? 'バスケメンター "ユナイト"' 
  : '';

  return (
    <Button 
      onClick={onClickSave}
      variant="contained"
      style={{ 
        width:280, 
        fontFamily: 'Oswald, sans-serif', 
        backgroundColor: '#24c1fe',    
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 6,
      }} 
    >
      <p style={{ fontSize: '18px', margin: 0 }}>{buttonText}</p> 
      <p style={{ fontSize: '14px', margin: 0 }}>{buttonTextJp}</p>
    </Button>
  );
};