import React, { useState } from 'react';
import { CSSProperties } from 'react';
import {
  Box,
  Button,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  FormControlLabel,
} from '@mui/material';

import { CharacterButton } from './CharacterButton';
import { Character } from './Character';

const UNITE_CHARACTER_IMAGE = "./assets/Unite_squea2-min.png";

// 表示するドメインを指定
const ALLOWED_DOMAINS = [
                          'http://localhost:3000/', 
                          'http://192.168.11.2:3000/',
                          'https://xs233977.xsrv.jp/'
                        ];

// 規約のスタイリング
const textStyle: CSSProperties = {
  textIndent: "-1em",
  paddingLeft: "1em",
  whiteSpace: "pre-wrap"
};
interface ConfigViewProps {
  onStart: () => Promise<void>;
}

export const ConfigView = (props: ConfigViewProps) => {
  const [open, setOpen] = useState(false);
  const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(true);
  const [isAgreeChecked, setIsAgreeChecked] = useState(false);

  // 現在のウィンドウのURLを取得
  const currentUrl = window.location.href;

  // 現在のURLが許可されたドメインのいずれかと一致するかチェック
  const shouldRender = ALLOWED_DOMAINS.includes(currentUrl);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.currentTarget;
    const bottom = target.scrollHeight - (target.scrollTop + target.clientHeight);
    if (bottom < 1) {
      setIsCheckboxDisabled(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setIsCheckboxDisabled(true);
    setIsAgreeChecked(false);
  };

  const handleAgreeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAgreeChecked(event.target.checked);
  };

  const enter = () => {
    setOpen(false);
    setIsCheckboxDisabled(true);
    setIsAgreeChecked(false);
    props.onStart();
  };

  return (
    <>
      <Box component="form">
        <Typography 
          variant="h3" 
          component="h1" 
          sx={{ 
            m: 1, 
            fontFamily: 'Oswald, sans-serif',
            fontWeight: 700,
            color: '#24c1fe',
            fontSize: '20px',  
            textAlign: 'center',
            position: 'relative',
            '&::before': {
              left: 0,
              content: '""',
              position: 'absolute',
              top: '50%',
              display: 'inline-block',
              width: '45px',
              height: '1px',
              backgroundColor: '#24c1fe',
            },
            '&::after': {
              right: 0,
              content: '""',
              position: 'absolute',
              top: '50%',
              display: 'inline-block',
              width: '45px',
              height: '1px',
              backgroundColor: '#24c1fe',
            }
          }}
          style={{ marginTop: 40, marginBottom: 40 }}
        >
          <p>
            "UNITE" ENGLISH ROOM
          </p>
          <p className="japanese-text">
            ユナイトと英語ではなそう
          </p>
        </Typography>
        <img
          src={UNITE_CHARACTER_IMAGE}
          alt="Character Image"
          className='img-responsive'
        />
        <Typography style={{ marginTop: '40px', textAlign: 'center', color: '#ffffff', fontSize: '16px', lineHeight: '1.6' }}>
          ユナイト・イングリッシュルームは、TUBCテクノロジーラボの実験的プロジェクトです。<br />
          AIユナイトと、 英語で会話が楽しめます。
          <br />
          <br />
          「クイズ」と「バスケのお悩み相談」の二つの部屋を用意したので、ぜひ遊んでみてください。
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ width:'580px', marginTop: '60px', textAlign: 'left', color: '#24c1fe', fontSize: '14px', lineHeight: '1.6' }}>
            <div style={textStyle}>
              ※規約は最後までご確認いただき、同意にチェックしていただいたうえでお入りください。
            </div>
            <div style={textStyle}>
              ※入力内容に個人情報や秘密情報等のセンシティブな情報を記載しないようお願いいたします。
            </div>
            <div style={textStyle}>
              ※本プロジェクトは実証実験段階になります。ユナイトの発言内容や設定等、事実ならびに公式と異なる場合がございますが予めご了承ください。
            </div>
          </div>
        </div>
        <Grid container justifyContent="center" style={{ marginTop: 20, width: '100%' }}>
          <Grid item xs={12} sm={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width:280,  padding: 10, minWidth: 300  }}>
            <CharacterButton characterSuffix="unite_quiz_prod_" onHandleClickOpen={ handleClickOpen } />
            <p style={{ color: 'white', fontSize: '16px', lineHeight: '1.6' }}>
              ユナイトがTUBCやバスケにまつわるクイズを出題。君の知識をチャレンジしてみよう！
            </p>
          </Grid>
          <Grid item xs={12} sm={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width:280,  padding: 10, minWidth: 300  }}>
            <CharacterButton characterSuffix="unite_coach_prod_" onHandleClickOpen={ handleClickOpen } />
            <p style={{ color: 'white', fontSize: '16px', lineHeight: '1.6'}}>
              バスケットボールする人の相談にのります。
              <br/>
              「ドリブルが上手くなりたい」、「試合になると緊張する」など相談してみよう。
            </p>
          </Grid>
        </Grid>
        {shouldRender && (
          <Grid container style={{ marginTop: 100, width: '100%' }}>
            <Typography
              variant="h3" 
              component="h1" 
              style={{ color: 'white', fontSize: '18px'}}
            >
              開発中のキャラクター
            </Typography>
            <Grid item xs={12} style={{ marginBottom: '-24px' }}>
              <Character characterSuffix="unite_test"  onStart={props.onStart} />
            </Grid>
            <Grid item xs={12} style={{ marginBottom: '-24px' }}>
              <Character characterSuffix="unite_test-quiz" onStart={props.onStart} />
            </Grid>
            <Grid item xs={12} style={{ marginBottom: '-24px' }}>
              <Character characterSuffix="unite_test-nishimura_quiz"  onStart={props.onStart} />
            </Grid>
          </Grid>
        )}
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle 
          style={{
            marginBottom: 10,
            textAlign: 'center',
            fontFamily: 'Oswald, sans-serif',
            fontSize: 24,
            color: '#24c1fe',
          }}
        >
          {"Welcome to \"UNITE\" ENGLISH ROOM"}
        </DialogTitle>
        <DialogContent
          dividers={false}
          style={{ maxHeight: '300px', overflowY: 'auto' }}
          onScroll={handleScroll}
        >
          <div style={{ fontSize: 14}}>
            <div style={{ fontSize: 16, textAlign: 'center', fontWeight: 'bold' }}>
            サービス利用規約
            </div>
            <br />
            この利用規約（以下「本規約」といいます）は、株式会社東京ユナイテッドバスケットボールクラブ（以下「当社」といいます）が提供する「ユナイト・イングリッシュルーム」（以下「本サービス」といいます）に関する利用条件を定めるものです。
            <br />
            <br />
            <div style={{ textAlign: 'center'}}>
            第1章 総則
            </div>
            第1条 （本サービスの利用）
            <br />
            本サービスを利用する者（以下「利用者」といいます）は、本規約に同意したうえで本サービスを利用します。
            <br />
            <br />
            第2条 （本規約の適用範囲および変更）
            <br />
            <div style={textStyle}>
            1. 本規約は本サービスの利用に関し、利用者に適用されます。
            </div>
            <div style={textStyle}>
            2. 当社は、利用者の事前の承諾を得ることなく、本サービスに本規約の改訂版を掲載することにより本規約を変更でき、利用者はこれに同意します。
            </div>
            <br />
            <div style={{ textAlign: 'center'}}>
            第2章 本サービスの利用
            </div>
            第3条 （本サービスの利用）
            <br />
            <div style={textStyle}>
            1. 利用者が、本サービスの利用環境（利用環境が変更された場合は、変更後の利用環境を意味します。以下同じ。）を満たさないときは、本サービスを利用できないことがあります。利用者は、本サービスを利用する場合、自らの費用および責任で、利用環境を整えて利用します。
            </div>
            <div style={textStyle}>
            2. 本サービスは、予告なくアップデート、編集、改編等の他、利用環境の変更、利用停止等（以下「変更等」といいます。）がなされることがあります。利用者は、当社により、予告なく変更等がなされる場合があることを、予め承諾します。また、当社は、変更等に基づき利用者に生じた損害について一切の責任を負いません。
            </div>
            <div style={textStyle}>
            3. 利用者は、本サービスの利用にあたって投稿する情報（入力する会話を含みますがこれらに限られません。以下同じ。）に個人情報の保護に関する法律に定める個人情報を含めてはなりません。利用者は、本サービスの利用にあたって投稿する情報に、第三者の権利を侵害するものが含まれないことを保証します。
            </div>
            <br />
            第4条 （禁止行為）
            <div style={textStyle}>
            1. 利用者は、以下の各号に該当する行為をおこなうことはできません。
            </div>
            <ol>
              <li>本サービスの運営を妨げる行為、その他本サービスに支障の恐れのある行為をすること</li>
              <li>他の利用者、第三者もしくは本システムに迷惑、不利益もしくは損害を与える行為、またはそれらの恐れのある行為をすること</li>
              <li>他の利用者、第三者もしくは本サイトの商標権、著作権、プライバシーその他の権利を侵害する行為またはそれらの恐れのある行為をすること</li>
              <li>過度に暴力的な表現、露骨な性的表現、児童ポルノもしくは児童虐待に相当する表現、人種、国籍、信条、性別、社会的身分、門地等による差別につながる表現、自殺、自傷行為、薬物乱用を誘引または助長する表現、その他反社会的な内容を含み他人に不快感を与える表現を投稿または送信する行為</li>
              <li>営業、宣伝、広告、勧誘、その他営利を目的とする行為、性行為やわいせつな行為を目的とする行為、面識のない異性との出会いや交際を目的とする行為、他のお客様に対する嫌がらせや誹謗中傷を目的とする行為、その他本アプリが予定している利用目的と異なる目的で本アプリを利用する行為</li>
              <li>宗教活動または宗教団体への勧誘行為</li>
              <li>犯罪行為に関連する行為、公序良俗に反する行為、またはこれらに該当するおそれのある行為</li>
              <li>法令または当社もしくは利用者が所属する業界団体の内部規則に違反する行為</li>
              <li>本サービスに関し利用しうる情報を改ざんする行為</li>
              <li>当社による本サービスの運営を妨害するおそれのある行為（本キャラクターまたは他の利用者に対する不適切なメッセージの送信、ソーシャルメディア上での本サービスに関連する不適切な投稿およびネットワーク上の攻撃によりコンピューターに過剰な負荷をかけるような行為を含むがこれに限られません。）</li>
              <li>公序良俗に反する行為その他法令に違反する行為、またはそれらの恐れのある行為をすること</li>
              <li>その他、当社が不適当と判断する行為</li>
            </ol>
            <div style={textStyle}>
            2. 利用者が前項に違反したことによって、本サービスに損害が生じたときは、利用者は当社に対し、違反したことにより利用者が得た利益を返還し、本サービスに生じた損失を賠償しなければなりません。
            </div>
            <br />
            第5条 （本サービスの停止または中断）
            <div style={textStyle}>
            1. 当社は、以下のいずれかに該当する場合には、利用者に事前に通知することなく、本サービスの利用の全部または一部を停止または一時的に中断することができます。
            </div>
            <ol>
              <li>本サービスに係るコンピューター・システムの点検または保守作業を行う場合</li>
              <li>コンピューター、通信回線等が事故により停止した場合</li>
              <li>火災、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合</li>
              <li>その他、当社が停止または中断を必要と判断した場合</li>
            </ol>
            <div style={textStyle}>
            2. 当社は、前項に基づき当社がおこなった措置に基づき利用者に生じた損害について一切の責任を負いません。
            </div>
            <br />
            第6条 （保証の否認および免責）
            <div style={textStyle}>
            1. 当社は、本サービス上でキャラクターによって提供される情報の正確性、完全性、信頼性、利用可能性および適時性および当該情報に不適切または不快な内容が含まれていないことにつき、いかなる保証もおこなうものではありません。
            </div>
            <div style={textStyle}>
            2. 利用者は、本サービスを利用することが、利用者に適用のある法令、業界団体の内部規則等に違反するか否かを自己の責任と費用に基づいて調査し、当社は、利用者による本サービスの利用が、利用者に適用のある法令、業界団体の内部規則等に適合することを何ら保証するものではありません。
            </div>
            <div style={textStyle}>
            3. 当社は、当社による本サービスの提供の中断、停止、利用不能または変更、本サービスの利用によるデータの消失または機器の故障もしくは損傷、その他本サービスに関連して利用者が被った損害につき、賠償する責任を一切負いません。
            </div>
            <div style={textStyle}>
            4. 本サービスサイトから他のウェブサイトへのリンクが提供され、または他のウェブサイトから当サービスサイトへのリンクが提供されている場合でも、当社は、当社ェブサイト以外のウェブサイトおよびそこから得られる情報に関していかなる理由に基づいても一切の責任を負いません。
            </div>
            <br />
            第7条 （本サービスの終了）
            <br />
            当社は、当社の都合により本サービスの全部または一部を終了することができます。この場合、本サービスの全部または一部は、当社が定めた日をもって終了します。
            <br />
            <br />
            第8条 （解除）
            <div style={textStyle}>
            1. 当社は、利用者が本規約に違反したときは、本サービスの利用契約を解除することができます。
            </div>
            <div style={textStyle}>
            2. 利用者は、前項によって解除されたときは、関連する他のサービスの利用も停止されることを予め確認します。
            </div>
            <br />
            第9条 （利用者の損害賠償請求）
            <div style={textStyle}>
            1. 利用者は、利用者による本規約の違反その他利用者の責めに帰すべき事由により当社が被った損害を賠償します。
            </div>
            <div style={textStyle}>
            2. 当社は、利用者との契約を解除したときであっても、利用者に対し、前項の規定に基づく損害賠償を請求します。
            </div>
            <br />
            <div style={{ textAlign: 'center'}}>
            第3章 その他
            </div>
            第10条 （反社会的勢力等の排除）
            <div style={textStyle}>
            1. 利用者は、反社会的勢力等と一切の関係を持たないものとします。
            </div>
            <div style={textStyle}>
            2. 利用者が法人である場合、利用者は、自らの役員および従業員が反社会的勢力等と関係を持たないように適切に指導するものとします。また、利用者は、本サービスに関連して、当社または第三者に対し、自らまたは第三者を利用して、暴力的な要求行為、法的な責任を超えた不当な要求行為、脅迫的な言動を行う、または暴力を用いる行為、風説を流布し、偽計を用いもしくは威力を用いて当社の信用を毀損または当社の業務を妨害する行為、その他これらに準ずる行為をしないことを確約します。
            </div>
            <br />
            第11条 （著作権）
            <div style={textStyle}>
            1. 利用者は、本サービスにおいて使用されている文言、画像およびデザイン等に関する著作権または商標権、その他全ての知的財産権を侵害する行為をおこなうことはできません。
            </div>
            <div style={textStyle}>
            2. 利用者は、本条の規定に違反して権利者あるいは第三者との間で問題が生じたときは、自己の責任と費用においてその問題を解決し、当社に何の迷惑または損害を与えてはなりません。
            </div>
            <br />
            第12条 （譲渡等禁止）
            <br />
            利用者は、本サービスの利用に基づいて発生する一切の権利および義務を、第三者に譲渡し、または担保に供することはできません。
            <br />
            <br />
            第13条 （準拠法および合意管轄）
            <div style={textStyle}>
            1. 本規約の準拠法は、日本法が適用されます。
            </div>
            <div style={textStyle}>
            2. 本規約に関して万一紛争が生じたときは、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
            </div>
            <br />
            第14条 （誠実協議）
            <br />
            本サービスおよび利用者は、本規約に定めのない事項または本規約の解釈に疑義が生じたときは、双方誠意を以って話し合い、これを解決します。
            <br />
            <br />
            <div style={{ textAlign: 'center'}}>
            第4章 付則
            </div>
            （実施・改定）
            <br />
            本規約は、2024年●月●日から実施します。
          </div>
        </DialogContent>
        <DialogActions style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <FormControlLabel
              control={
                <Checkbox
                  checked={isAgreeChecked}
                  onChange={handleAgreeChange}
                  color="primary"
                  disabled={isCheckboxDisabled}
                  style={{ transform: 'scale(0.8)' }}
                />
              }
              label={<span style={{ fontSize: '0.8rem' }}>同意します / Agree</span>}
          />
          <Button 
            onClick={enter} 
            variant="contained"
            style={{
              marginTop: 20, 
              marginBottom: 20,
              width: 180,
              fontFamily: 'Oswald, sans-serif', 
              fontSize: 18,
              backgroundColor: !isAgreeChecked ? '#96908E' : '#24c1fe',
              color:'#FFFFFF'
            }}
            autoFocus
            disabled={!isAgreeChecked}
          >
            ENTER
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
