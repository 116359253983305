import './App.css';

import {
  AdditionalPhonemeInfo,
  Character,
  EmotionEvent,
  HistoryItem,
  InworldConnectionService,
  InworldPacket,
} from '@inworld/web-core';
import { ArrowBackRounded } from '@mui/icons-material';
import { Box, Button, Grid } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Chat } from './app/chat/Chat';
import { Avatar } from './app/components/3dAvatar/Avatar';
import { CircularRpmAvatar } from './app/components/CircularRpmAvatar';
import ControlBar from './app/components/ControlBar';
import {
  AnimationFiles,
  AnimationSequence,
} from './app/components/innequin/data/animations';
import { Innequin } from './app/components/innequin/Innequin';
import { Layout } from './app/components/Layout';
import {
  ChatWrapper,
  MainWrapper,
  SimulatorHeader,
} from './app/components/Simulator';
import { ConfigView } from './app/configuration/ConfigView';
import { InworldService } from './app/connection';
import {
  get as getConfiguration,
  save as saveConfiguration,
} from './app/helpers/configuration';
import { JSONToPreviousDialog, toInt } from './app/helpers/transform';
import {
  BODY_TEXTURE_TYPE,
  CHAT_VIEW,
  ConfigurationSession,
  EmotionsMap,
} from './app/types';
import { Config } from './config';
import * as defaults from './defaults';
import ReactGA4 from 'react-ga4';

const UNITE_AVATAR_URL = './assets/Unite.glb';
const TIMEOUT_DURATION_RESUME  = 180 * 1000;
const TIMEOUT_DURATION_CLOSING = 20 * 1000;

let interactionTimeout: number | undefined;

interface CurrentContext {
  characters: Character[];
  chatting: boolean;
  connection?: InworldConnectionService;
}

/**
 * 指定されたキャラクターオブジェクトからキャラクター名を抽出します。
 * `character.resourceName`から最後のパスセグメントを取得して返します。
 * 
 * @param character - キャラクターオブジェクト
 * @returns キャラクター名の文字列
 */
function getCharacterName(character: Character): string {
  const parts = character.resourceName.split('/');
  return parts[parts.length - 1];
}

/**
 * 指定された時間が経過するとトリガーを発火させるタイマーを設定します。
 * 既存のタイマーがある場合は、それをクリアして新しいタイマーを設定します。
 * タイムアウト後には指定されたトリガーを発火し、Google Analytics にイベントを送信します。
 * 
 * @param service - インワールドコネクションサービスのインスタンス
 * @param category - Google Analytics イベントのカテゴリー
 * @param label - トリガーと Google Analytics イベントのラベル
 * @param duration - タイマー発動までの時間設定
 */
function setupInteractionTimeout(service: any, category: string, label: string, duration: number) {
  // タイマークリア
  if (interactionTimeout !== undefined) {
    clearTimeout(interactionTimeout);
  }

  console.log("setTimeout", duration);

  interactionTimeout = setTimeout(() => {
    console.log("sendTrigger", label);
    service.connection.sendTrigger(label);

    ReactGA4.event({
      category: category,
      action: label,
    });
  }, duration) as unknown as number;
}

function App() {
  const formMethods = useForm<ConfigurationSession>({ mode: 'onChange' });

  const [bodyTexture, setBodyTexture] = useState(BODY_TEXTURE_TYPE.WOOD1);
  const [connection, setConnection] = useState<InworldConnectionService>();
  const [character, setCharacter] = useState<Character>();
  const [characters, setCharacters] = useState<Character[]>([]);
  const [chatHistory, setChatHistory] = useState<HistoryItem[]>([]);
  const [prevChatHistory, setPrevChatHistory] = useState<HistoryItem[]>([]);
  const [prevTranscripts, setPrevTranscripts] = useState<string[]>([]);
  const [chatting, setChatting] = useState(false);
  const [chatView, setChatView] = useState(CHAT_VIEW.TEXT);
  const [initialized, setInitialized] = useState(false);
  const [phonemes, setPhonemes] = useState<AdditionalPhonemeInfo[]>([]);
  const [emotionEvent, setEmotionEvent] = useState<EmotionEvent>();
  const [avatar, setAvatar] = useState('');
  const [emotions, setEmotions] = useState<EmotionsMap>({});

  // Inworld からの trigger を定義
  const [trigger, setTrigger] = useState("");
  const triggerRef = useRef(trigger);
  useEffect(() => {
    triggerRef.current = trigger;
  }, [trigger]);


  const stateRef = useRef<CurrentContext>();
  stateRef.current = {
    characters,
    chatting,
    connection,
  };

  const onHistoryChange = useCallback((history: HistoryItem[]) => {
    setChatHistory(history);
  }, []);

  const openConnection = useCallback(
    async (previousState?: string) => {
      const form = formMethods.getValues();
      const currentTranscript = connection?.getTranscript() || '';

      setPrevTranscripts([
        ...prevTranscripts,
        ...(currentTranscript ? [currentTranscript] : []),
      ]);
      setPrevChatHistory([...prevChatHistory, ...chatHistory]);
      setChatHistory([]);
      setChatting(true);
      setChatView(form.chatView!);

      const duration = toInt(form.audio?.stopDuration ?? 0);
      const ticks = toInt(form.audio?.stopTicks ?? 0);
      const previousDialog = form.continuation?.enabled
        ? JSONToPreviousDialog(form.continuation.previousDialog!)
        : [];

      const service = new InworldService({
        onHistoryChange,
        capabilities: {
          ...(form.chatView !== CHAT_VIEW.TEXT && { phonemes: true }),
          ...(form.chatView === CHAT_VIEW.TEXT && { interruptions: true }),
          emotions: true,
          narratedActions: true,
        },
        ...(previousDialog.length && { continuation: { previousDialog } }),
        ...(previousState && { continuation: { previousState } }),
        ...(duration &&
          ticks && {
            audioPlayback: {
              stop: { duration, ticks },
            },
          }),
        sceneName: form.scene?.name!,
        playerName: form.player?.name!,
        onPhoneme: (phonemes: AdditionalPhonemeInfo[]) => {
          setPhonemes(phonemes);
        },
        onReady: async () => {
          console.log('Ready!');
        },
        onDisconnect: () => {
          console.log('Disconnect!');
        },
        onMessage: (inworldPacket: InworldPacket) => {
          // console.log('onMessage', inworldPacket)
          if (
            inworldPacket.isEmotion() &&
            inworldPacket.packetId?.interactionId
          ) {
            setEmotionEvent(inworldPacket.emotions);
            setEmotions((currentState) => ({
              ...currentState,
              [inworldPacket.packetId.interactionId]: inworldPacket.emotions,
            }));
          }

          // Inworld からの send_trigger を検知する
          if (character) {
            const characterName = getCharacterName(character);
            if (
                 characterName === 'unite_quiz_prod_' 
              || characterName === 'unite_test-quiz'
              || characterName === 'unite_test-nishimura_quiz' 
              ) {
              if (inworldPacket.isTrigger()) {
                setTrigger(inworldPacket.trigger.name);
              }
            }
          }
        },

        // キャラクターの発話終了イベント
        onStopPlaying: () => {
          console.log('onStopPlaying: ', triggerRef.current)

          // タイマークリア
          if (interactionTimeout !== undefined) {
            clearTimeout(interactionTimeout);
          }

          if (character) {
            const characterName = getCharacterName(character);
            if (
                 characterName === 'unite_coach_prod_'
              || characterName === 'unite_test'
              ) {
              /* バスケメンター "ユナイト"の場合 */

              // 進行が止まった場合の処理
              setupInteractionTimeout(service, 'Coach', "20sec_Pause", TIMEOUT_DURATION_RESUME);
            }else {
              /* TUBC クイズチャレンジの場合 */

              const trigger = triggerRef.current;
              if (trigger.includes('_answer')) {
                let sendTrigger = "";
            
                // 正規表現を使って、トリガー文字列内の数字を取得
                const match = /inworld\.goal\.complete\.Topic(.+)_Q(\d+)_answer/.exec(trigger);
                if (match) {
                    const topic = match[1];
                    let number = parseInt(match[2]);
            
                    // Q3 以下の場合、インクリメントして次の Q をトリガー
                    if (number < 3) {
                      number += 1;
                      sendTrigger = `Topic${topic}_Q${number}`;
  
                    } else {
                      // Q3 の場合、"Ask_for_Interests" に置き換え
                      sendTrigger = "Ask_for_Interests";
                    }
                }

                // トリガーを送る
                if (sendTrigger) {
                    console.log("sendTrigger:", sendTrigger);
    
                    service.connection.sendTrigger(sendTrigger);

                    ReactGA4.event({
                      category: 'Quiz',
                      action: sendTrigger,
                    });
                } else {
                    console.error("sendTrigger is undefined");
                }
                setupInteractionTimeout(service, 'Quiz', "quiz_request", TIMEOUT_DURATION_RESUME);
              } else if (trigger.includes('Ask_for_Interests')) {
                setupInteractionTimeout(service, 'Quiz', "20sec_Pause", TIMEOUT_DURATION_CLOSING);
              } else {
                setupInteractionTimeout(service, 'Quiz', "quiz_request", TIMEOUT_DURATION_RESUME);
              }
            }
          }
        },
      });
      const characters = await service.connection.getCharacters();
      const character = characters.find(
        (c: Character) => c.resourceName === form.character?.name,
      );

      if (character) {
        service.connection.setCurrentCharacter(character);

        const assets = character?.assets;
        const rpmImageUri = assets?.rpmImageUriPortrait;
        const avatarImg = assets?.avatarImg;
        setAvatar(avatarImg || rpmImageUri || '');
      }

      setConnection(service.connection);

      setCharacter(character);
      setCharacters(characters);

      if (character) {
        const characterName = getCharacterName(character);
        console.log('characterName: ', characterName)

        ReactGA4.event({
          category: 'Select Character',
          action: characterName,
        });

        switch(characterName) {
          case 'unite_quiz_prod_':
          case 'unite_test-quiz':
          case 'unite_test-nishimura_quiz':
            service.connection.sendTrigger('quiz_request');
            break;
          case 'unite_coach_prod_':
          case 'unite_test':
            service.connection.sendTrigger('Login');
            break;
        }
      }
    },
    [
      chatHistory,
      connection,
      formMethods,
      onHistoryChange,
      prevChatHistory,
      prevTranscripts,
    ],
  );

  const stopChatting = useCallback(async () => {
    // Disable flags
    setChatting(false);

    // Clear collections
    setChatHistory([]);
    setPrevChatHistory([]);

    // Close connection and clear connection data
    connection?.close();
    setConnection(undefined);
    setCharacter(undefined);
    setCharacters([]);

    // 初期化
    setTrigger("");
  }, [connection]);

  const resetForm = useCallback(() => {
    formMethods.reset({
      ...defaults.configuration,
    });
    saveConfiguration(formMethods.getValues());
  }, [formMethods]);

  useEffect(() => {
    const configuration = getConfiguration();

    formMethods.reset({
      ...(configuration
        ? (JSON.parse(configuration) as ConfigurationSession)
        : defaults.configuration),
    });

    setInitialized(true);
  }, [formMethods]);

  useEffect(() => {
    return () => {
      if (interactionTimeout) {
        clearTimeout(interactionTimeout);
      }
    };
  }, []);

  // ユーザーのクリックを検知するハンドラ
  const handleUserInteraction = () => {
    if (interactionTimeout !== undefined) {
      clearTimeout(interactionTimeout);
    }
  };

  // クリックイベントリスナーを追加
  useEffect(() => {
    window.addEventListener('click', handleUserInteraction);

    // クリーンアップ関数でイベントリスナーを削除
    return () => {
      window.removeEventListener('click', handleUserInteraction);
      if (interactionTimeout !== undefined) {
        clearTimeout(interactionTimeout);
      }
    };
  }, []);

  const content = chatting ? (
    <>
      {character ? (
        <MainWrapper>
          {false && (
            <Box
              sx={{
                borderRadius: '1.75rem',
                backgroundColor: 'white',
                top: '50px',
                width: '9%',
                height: '50%',
              }}
            >
              <ControlBar
                bodyTexture={bodyTexture}
                setBodyTexture={setBodyTexture}
                visible={true}
              />
            </Box>
          )}
          <ChatWrapper>
            {chatView === CHAT_VIEW.AVATAR && (
              <Avatar
                emotionEvent={emotionEvent}
                phonemes={phonemes}
                visible={chatView === CHAT_VIEW.AVATAR}
                url={
                  Config.RPM_AVATAR ||
                  UNITE_AVATAR_URL ||
                  defaults.DEFAULT_RPM_AVATAR
                }
              />
            )}
            {chatView === CHAT_VIEW.INNEQUIN && (
              <Innequin
                animationFiles={AnimationFiles}
                animationSequence={AnimationSequence}
                bodyTexture={bodyTexture}
                emotionEvent={emotionEvent}
                phonemes={phonemes}
                visible={chatView === CHAT_VIEW.INNEQUIN}
                modelURI={Config.MODEL_URI}
              />
            )}
            <SimulatorHeader>
              <Grid container>
                <Grid item sm={6}>
                  <Button
                    startIcon={<ArrowBackRounded />}
                    onClick={stopChatting}
                    variant="contained"
                    style={{ fontFamily: 'Oswald, sans-serif', backgroundColor: '#24c1fe' }} 
                  >
                    BACK
                  </Button>
                </Grid>
                {chatView === CHAT_VIEW.TEXT && (
                  <Grid item sm={6}>
                    {avatar && (
                      <CircularRpmAvatar
                        src={avatar}
                        name={character.displayName}
                        size="48px"
                        sx={{ display: ['none', 'flex'] }}
                      />
                    )}
                  </Grid>
                )}
              </Grid>
            </SimulatorHeader>
            <Chat
              chatView={chatView}
              chatHistory={[...prevChatHistory, ...chatHistory]}
              prevTranscripts={prevTranscripts}
              connection={connection!}
              emotions={emotions}
              onRestore={openConnection}
              stopChatting={stopChatting}
            />
          </ChatWrapper>
        </MainWrapper>
      ) : (
        'Loading...'
      )}
    </>
  ) : (
    <ConfigView
      onStart={() => openConnection()}
    />
  );

  return (
    <FormProvider {...formMethods}>
      <Layout>{initialized ? content : ''}</Layout>
    </FormProvider>
  );
}

export default App;
